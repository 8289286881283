export default class XhrForm {
    constructor($el, options = {}) {
        this.$el = $el;
        this.options = {
            classNameHasError: "has-error",
            classNameIsBusy: "is-busy",
            classNameIsError: "is-error",
            classNameIsLoading: "is-loading",
            classNameIsServerError: "is-serverError",
            classNameIsSuccess: "is-success",
            dataAttrConfirm: "xhrFormConfirm",
            headers: {},
            selectorField: ".js-xhrFormField",
            selectorFieldError: ".js-xhrFormFieldError",
            ...options,
        };

        this.init();
        this.initListeners();
    }

    init() {
        this.headers = {
            Accept: "application/json",
            "Cache-Control": "no-cache",
            "X-Requested-With": "XMLHttpRequest",
            ...this.options.headers,
        };
        this.method = this.$el.getAttribute("method");
        this.request = null;
        this.url = this.$el.getAttribute("action");
    }

    initListeners() {
        this.$el.addEventListener("submit", this.onSubmit.bind(this));
    }

    displayError(error) {
        if (this.$errors) {
            const $error = document.createElement("li");
            $error.innerHTML = error;
            this.$errors.appendChild($error);
        }
    }

    displayErrors(errors) {
        let $field;
        let $fieldError;
        let $input;
        let name;

        for (const key in errors) {
            name = key.replace(/\./g, "[") + Array(key.split(".").length).join("]");
            $input = this.$el.querySelector(`[name="${name}"]`);

            if (!$input) {
                $input = this.$el.querySelector(`[name="${name}[]"]`);
            }

            if ($input) {
                $field = $input.closest(this.options.selectorField);

                if ($field) {
                    $field.classList.add(this.options.classNameHasError);
                    $fieldError = $field.querySelector(this.options.selectorFieldError);

                    if ($fieldError) {
                        $fieldError.innerText = errors[key].pop();
                    }
                }
            }

            errors[key].forEach((error) => this.displayError(error));
        }
    }

    onError(error) {
        this.reset();
        this.updateCsrfToken(error.response.headers);
        this.$el.classList.remove(this.options.classNameIsBusy);
        this.$el.dispatchEvent(new CustomEvent("xhrform:complete", { detail: error.response.data }));

        switch (error.response.status) {
            case 422:
                this.$el.classList.add(this.options.classNameIsError);
                this.$el.dispatchEvent(new CustomEvent("xhrform:validationerror"));

                if (error.response.data.result === "error") {
                    this.displayError(error.response.data.message);
                } else {
                    this.displayErrors(error.response.data.errors);
                }

                break;

            default:
                this.$el.classList.add(this.options.classNameIsServerError);
                this.$el.dispatchEvent(new CustomEvent("xhrform:servererror"));
                break;
        }
    }

    onSubmit(e) {
        e.preventDefault();

        grecaptcha.ready(async () => {
            const token = await grecaptcha.execute("6LfQI1UoAAAAAC6HPQ-7ueZDtW3jeuKFu0Ii-H8W", { action: "subscribe" });
            this.submit(token);
        });
    }

    onSuccess(response) {
        if (response.data.hasOwnProperty("location")) {
            // Redirect
            window.location = response.data.location;
        }

        this.reset();
        this.updateCsrfToken(response.headers);
        this.$el.classList.remove(this.options.classNameIsBusy);
        this.$el.dispatchEvent(new CustomEvent("xhrform:complete", { detail: response.data }));
        this.$el.dispatchEvent(new CustomEvent("xhrform:success", { detail: response.data }));

        if (response.data.hasOwnProperty("success")) {
            this.$el.classList.add(this.options.classNameIsSuccess);
        }
    }

    reset() {
        this.$el.reset();
        this.$el.classList.remove(this.options.classNameHasErrors, this.options.classNameHasServerError, this.options.classNameIsSuccess);

        for (const $field of this.$el.querySelectorAll(this.options.selectorField)) {
            $field.classList.remove(this.options.classNameHasError);
        }

        for (const $fieldError of this.$el.querySelectorAll(this.options.selectorFieldError)) {
            $fieldError.innerHTML = "";
        }
    }

    submit(token) {
        const data = new FormData(this.$el);
        data.append("token", token);

        const request = {
            data,
            headers: this.headers,
            method: this.method,
            url: this.url,
        };

        if (this.$el.dataset[this.options.dataAttrConfirm]) {
            if (!window.confirm(this.$el.dataset[this.options.dataAttrConfirm])) {
                return false;
            }
        }

        this.$el.classList.remove(this.options.classNameIsSuccess);
        this.$el.classList.add(this.options.classNameIsBusy);
        this.$el.dispatchEvent(new CustomEvent("xhrform:beforesend"));

        axios(request).then(this.onSuccess.bind(this)).catch(this.onError.bind(this));

        this.$el.dispatchEvent(new CustomEvent("xhrform:send", { detail: request.data }));
    }

    updateCsrfToken(headers) {
        const token = headers.hasOwnProperty("x-csrf-token") ? headers["x-csrf-token"] : null;

        if (token) {
            const $$input = document.querySelectorAll("input[name=csrf_token]");
            const $meta = document.head.querySelector("meta[name=csrf_token]");

            $$input.forEach(($input) => {
                $input.value = token;
            });

            if ($meta) {
                $meta.content = token;
            }
        }
    }
}
