import './bootstrap'

import Equinox from './components/Equinox'
import Modal from './components/Modal'
import Title from './components/Title'
import XhrForm from './components/XhrForm'

window.app.init([
    { name: 'equinox', obj: Equinox },
    { name: 'modal', obj: Modal },
    { name: 'title', obj: Title },
    { name: 'xhrForm', obj: XhrForm },
])