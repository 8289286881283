export default class Equinox
{
    constructor($el, options = {}) {
        this.$el = $el
        this.options = {
            classNameIsClosest: 'is-closest',
            classNameIsVisible: 'is-visible',
            selector: '.js-equinox',
            selectorNode: '.js-equinoxNode',
            ...options
        }
        this.init()
    }

    init() {
        this.$$nodes = this.$el.querySelectorAll(this.options.selectorNode)    
        this.$closestNode = null
        this.axis = null
        this.containerH = 0
        this.containerW = 0
        this.isIntersecting = true
        this.lowestNodeDepth = null
        this.nodes = []
        this.scroll = null

        this.update()
        this.observe()
        this.observeNodes()

        window.addEventListener('load', this.update.bind(this), { passive: true })
        window.addEventListener('resize', this.update.bind(this), { passive: true })
    }

    animate(time) {
        if (this.isIntersecting || time === null) {
            let scroll
            let update = (time === null)

            if (this.axis === 'x') {
                scroll = this.$el.scrollLeft || window.scrollX
            }

            if (this.axis === 'y') {
                scroll = this.$el.scrollTop || window.scrollY
            }

            if (scroll !== this.scroll) {
                this.scroll = scroll
                update = true
            }

            if (update) {
                let $closestNode = this.$closestNode

                this.nodes.forEach(node => {
                    const { $node, depth, pos, size } = node

                    if ($node.isIntersecting) {
                        let equinox
                        let equinoxAbs
                        let equinoxPos

                        if (this.axis === 'x') {
                            equinox = ((scroll + this.containerW) - pos) / (this.containerW + size)
                        } else {
                            equinox = ((scroll + this.containerH) - pos) / (this.containerH + size)
                        }

                        equinox = (equinox * -2) + 1

                        if (equinox > 1) {
                            equinox = 1
                        }

                        if (equinox < -1) {
                            equinox = -1
                        }

                        equinoxAbs = Math.abs(equinox)

                        if (equinoxAbs < 0.005) {
                            equinox = 0
                            equinoxAbs = 0
                        }

                        equinoxPos = (equinox + 1) * 0.5

                        if (equinox !== $node.equinox) {
                            $node.equinox = equinox
                            $node.style.setProperty('--equinox', equinox)
                            $node.style.setProperty('--equinox-abs', equinoxAbs)
                            $node.style.setProperty('--equinox-pos', equinoxPos)

                            $node.dispatchEvent(new CustomEvent('equinox:change', {
                                detail: {
                                    equinox,
                                    equinoxAbs,
                                    equinoxPos
                                }
                            }))

                            if (depth === this.lowestNodeDepth) {
                                if ($closestNode === null || equinoxAbs < $closestNode.equinoxAbs) {
                                    $closestNode = $node
                                }
                            }
                        }
                    }
                })

                if ($closestNode !== this.$closestNode) {
                    this.nodes.forEach(node => node.$node.classList.remove(this.options.classNameIsClosest))
                    this.$closestNode = $closestNode
                    this.$closestNode.classList.add(this.options.classNameIsClosest)
                    this.$closestNode.dispatchEvent(new CustomEvent('equinox:closest'))
                }
            }

            window.requestAnimationFrame(this.animate.bind(this))
        }
    }

    getNodeDepth($node) {
        let depth = 1;
        let $parent = $node.parentElement

        while ($parent !== this.$el) {
            $parent = $parent.parentElement
            depth++
        }

        return depth
    }

    observe() {
        const io = new IntersectionObserver(entries => {
            this.isIntersecting = entries[0].isIntersecting

            if (this.isIntersecting) {
                window.requestAnimationFrame(() => this.animate(null))
            }
        })

        const ro = new ResizeObserver(this.update.bind(this))

        io.observe(this.$el)
        ro.observe(document.body)
    }

    observeNodes() {
        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const $node = entry.target
                const wasIntersecting = $node.isIntersecting

                $node.isIntersecting = entry.isIntersecting

                if (wasIntersecting !== entry.isIntersecting) {
                    $node.classList.toggle(this.options.classNameIsVisible, entry.isIntersecting)
                    $node.dispatchEvent(new CustomEvent('equinox:visible', { detail: entry.isIntersecting }))
                }
            })
        }, {
            root: (this.$el === document.body) ? null : this.$el,
        })

        this.nodes.forEach(node => io.observe(node.$node))
    }

    update() {
        this.axis = null
        this.containerH = Math.min(window.innerHeight, this.$el.clientHeight)
        this.containerW = Math.min(window.innerWidth, this.$el.clientWidth)
        this.nodes = []

        if (this.$$nodes.length) {
            let scroll = 0

            if (this.$el.scrollWidth > this.containerW) {
                this.axis = 'x'
                scroll = this.$el.scrollLeft || window.scrollX
            }
    
            if (this.$el.scrollHeight > this.containerH) {
                this.axis = 'y'
                scroll = this.$el.scrollTop || window.scrollY
            }

            if (this.axis) {
                this.$$nodes.forEach($node => {
                    if ($node.closest(this.options.selector) === this.$el) {
                        const depth = this.getNodeDepth($node)
                        const rect = $node.getBoundingClientRect()

                        let node = { 
                            $node,
                            depth: depth,
                        }
        
                        if (this.axis === 'x') {
                            node.pos = rect.x + scrollX
                            node.size = rect.width
                        }
        
                        if (this.axis === 'y') {
                            node.pos = rect.y + scrollY
                            node.size = rect.height
                        }
        
                        if (!this.lowestNodeDepth || depth < this.lowestNodeDepth) {
                            this.lowestNodeDepth = depth
                        }

                        this.nodes.push(node)
                    }
                })

                this.animate(null)
            }
        }
    }
}