export default class Title
{
    constructor($el, options = {}) {
        this.$el = $el
        this.options = {
            classNameWord: 'word',
            classNameWordInner: 'word__inner',
            ...options
        }

        this.init()
    }

    init() {
        const text = this.$el.innerText
        const words = text.split(/\s+/)

        this.$el.innerText = ''

        words.forEach((word, i) => {
            const $inner = document.createElement('span')
            $inner.className = this.options.classNameWordInner
            $inner.innerText = word

            const $word = document.createElement('span')
            $word.className = this.options.classNameWord
            $word.appendChild($inner)

            this.$el.append($word)
        })
    }
}
