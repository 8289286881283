export default class Modal
{
    constructor($el, options = {}) {
        this.$el = $el
        this.options = {
            classNameHasModal: 'has-modal',
            classNameIsVisible: 'is-visible',
            selectorHide: '.js-modalHide',
            selectorSubmit: '.js-modalSubmit',
            ...options
        }

        this.init()
        this.initListeners()
    }

    init() {
        const name = this.$el.dataset.modal

        if (name) {
            document.querySelectorAll(`a[href='#modal-${name}']`).forEach($a => {
                $a.dataset.modalShow = name
            })

            this.$$hide = this.$el.querySelectorAll(this.options.selectorHide)
            this.$$input = this.$el.querySelectorAll('input')
            this.$$submit = this.$el.querySelectorAll(this.options.selectorSubmit)
            this.$$trigger = document.querySelectorAll(`[data-modal-show='${name}']`)
            this.isVisible = false
            this.name = name
        }
    }

    initListeners() {
        document.addEventListener('modal:hideall', this.onHideAll.bind(this), false)
        document.addEventListener('keydown', this.onKeyDown.bind(this), false)
        this.$$trigger.forEach($trigger => $trigger.addEventListener('click', this.onTriggerClick.bind(this)))
        this.$$hide.forEach($hide => $hide.addEventListener('click', this.onHideClick.bind(this)))
        this.$el.addEventListener('click', this.onOverlayClick.bind(this), false)
    }

    hide() {
        if (this.isVisible) {
            this.$el.dispatchEvent(new CustomEvent('modal:hide', {
                detail: {
                    modal: this
                }
            }))

            this.$$input.forEach($input => {
                $input.value = ''
            })

            document.body.classList.remove(this.options.classNameHasModal)
            this.$el.classList.remove(this.options.classNameIsVisible)
            this.$el.querySelectorAll('form').forEach($form => $form.reset())
            this.isVisible = false
        }
    }

    onHideAll(e) {
        if (e.detail.$self !== this.$el) {
            this.hide()
        }
    }

    onHideClick(e) {
        e.preventDefault()
        this.hide()
    }

    onInputKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.submit()
        }
    }

    onKeyDown(e) {
        if (e.key === 'Escape') {
            this.hide()
        }
    }

    onOverlayClick(e) {
        if (e.target === e.currentTarget) {
            this.hide()
        }
    }

    onSubmitClick(e) {
        e.preventDefault()
        this.submit()
    }

    onTriggerClick(e) {
        e.preventDefault()
        this.show()
    }

    show() {
        document.dispatchEvent(new CustomEvent('modal:hideall', {
            detail: {
                $self: this.$el
            }
        }))

        document.body.classList.add(this.options.classNameHasModal)
        this.$el.classList.add(this.options.classNameIsVisible)
        this.isVisible = true

        window.requestAnimationFrame(() => {
            const $input = this.$el.querySelector('input')

            if ($input) {
                $input.focus()
            }

            this.$el.dispatchEvent(new CustomEvent('modal:show', {
                detail: {
                    modal: this
                }
            }))
        })
    }

    submit() {
        // console.log('submit')
        this.$el.dispatchEvent(new CustomEvent('modal:submit', {
            detail: {
                modal: this
            }
        }))
    }
}
